.wallet-option {
    margin-bottom: 100px;
    width: 100%;
    margin-top: 100px;
z-index: 10;
    .connect-option {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }


}

@media all and (max-width:767px) {
    .wallet-option {
        margin-bottom: 0px;
        margin-top: 0;
    }

    .wallet-img {
        width: 100%;
        height: 100%;
        margin-bottom: 20px;
    }
}