.our-nft {
    margin-bottom: 2rem;
    h4,p{
        margin-bottom: 0 !important;
    }
    .nft-head {
        margin-bottom: 45px;


    }
.see-more-btn{
    color: #FFF;
text-align: center;
font-family: "Clash Display";
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: normal;
display: inline-flex;
padding: 11.111px 40.741px;
align-items: flex-start;
gap: 7.407px;
border-radius: 44.444px;
border: 0.741px solid #FFF;
opacity: 0.9;
background: transparent;
}
    .tab-btn-div {
        display: flex;
        justify-content: space-between;

    }
    .art-btn {
        display: inline-flex;
        height: 53.333px;
        padding: 7.407px 40.741px;
        justify-content: center;
        align-items: center;
        gap: 7.407px;
        flex-shrink: 0;
        border-radius: 44.444px;
        // background: var(--Blue-1, #2F80ED);
        background: transparent;
        border-color: white;
        font-size: 17px;
        font-weight: 600;
    }

    .btn-tabs {
        display: inline-flex;
        height: 50.667px;
        padding: 7.407px 40.741px;
        justify-content: center;
        align-items: center;
        gap: 7.407px;
        flex-shrink: 0;
        border-radius: 44.444px;
        border: 0.741px solid #FF2966;
        opacity: 0.9;
        background: transparent;
        transition: background 0.3s ease, border-color 0.3s ease;
        color: white;
        font-size: 17px;
    font-weight: 600;
    }
    
    .btn-tabs.active-tab {
        border: 0.741px solid #FF2966;
        background: #FF0048;
        color: #FFF; /* Optional: Change text color for active tab */
    }
    .left-gradient {
        left: -1%;
        position: absolute;
        bottom: -54%;
        z-index: -1;

    
        .gradient-img {
            height: 1122px;
        }
    }
    
    .right-gradient {
        position: absolute;
        bottom: -62%;
        overflow: hidden;
        right: -9%;
        transform: rotate(180deg);
    z-index: -1;
        .gradient-img {
            height: 1122px;
        }
    }
}


.nft-card {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    // gap: -16px;
    // padding: 4% 5%;
    padding: 4% 0;


    .card {
        display: flex;
        width: 240.064px;
        height: 349.63px;
        flex-direction: column;
        align-items: flex-start;
        flex-shrink: 0;
        border-radius: 14.815px;
        border: 1.111px solid #695a5e;
        background: linear-gradient(155deg, rgba(0, 0, 0, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
        box-shadow: 0px 2.963px 36.296px 0px rgba(0, 7, 72, 0.12);
        backdrop-filter: blur(5.55555534362793px);
        padding: 10px;
    }
    .card-body{
        padding: 3px;
    }

    .card-title {
        color: #FFF;
        font-family: Poppins;
        font-size: 18.296px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin-bottom: 0;
    }

    .card-text {
        color: #babbc7;
        font-family: Poppins;
        font-size: 14.63px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    h4 {
        color: #FFF;
        font-family: Poppins;
        font-size: 14.815px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    p {
        color: #FFF;
        font-family: Poppins;
        font-size: 10.37px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .loss {
        color: var(--Down-Perc, #F00);
        text-align: right;
        font-family: Poppins;
        font-size: 10.37px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }

    .gain {
        color: #24FF00;
        text-align: right;
        font-family: Poppins;
        font-size: 10.37px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
}
.see-more-div{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media all and (max-width:1400px) {
    .nft-card {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 15px;
        row-gap: 25px;
    }
}
@media all and (max-width:991px){
    .nft-card {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 15px;
        row-gap: 15px;

    }
}

@media all and (max-width:767px){
    .our-nft .btn-tabs {
        padding: 5.407px 21.741px !important;
        font-weight: 500;
        width: 159px;
        font-size: 14px !important;
        margin-bottom: 15px;
    }
    .nft-card {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }
    .nft-card .card{
        width: 100%;
        height: 100%;
    }
}