.faq {
    margin-bottom: 150px !important;
 
    .faq-head {
        margin-bottom: 50px;

     
    }

    .accordion {
        border-color: transparent;
        --bs-accordion-border-color:none;
        .accordion-item {
            border-radius: 30.148px;
            border: 0.741px solid var(--2, #695a5e) !important;
            background: #131850;
            color: white;
            margin-bottom: 20px;
            background: var(--1, linear-gradient(155deg, rgba(0, 0, 0, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%));
    
            &.expanded {
                /* Ensure a single border surrounds the entire expanded item */
                border: 1px solid var(--2, #A0DDDE);
    
                .accordion-header {
                    border: none; /* Remove individual header border */
                    border-radius: 8.148px 8.148px 0 0; /* Round top corners only */
                }
    
                .accordion-body {
                    border: none; /* Remove individual body border */
                    border-top: 1px solid var(--2, #A0DDDE); /* Seamless connection with header */
                    border-radius: 0 0 8.148px 8.148px; /* Round bottom corners only */
                    background: var(--1, linear-gradient(155deg, rgba(0, 0, 0, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%));
                }
            }
    
            .accordion-header {
                // border-radius: 30.148px;
                // border: 0.741px solid var(--2, #56527c);
                color: white;
    
                &:focus {
                    box-shadow: none;
                    border-color: transparent;
                }
    
                .accordion-button {
                    // border-radius: 30.148px;
                    background: transparent !important;
                    color: white !important;
                    // border-color: #56527c;
    
                    &:focus {
                        box-shadow: none;
                        border-color: #56527c;
                    }
    
                    &:active {
                        color: #A0DDDE !important;
                        font-size: 13.333px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%;
                    }
    
                    &.collapsed {
                        color: #FFF;
                        font-size: 16.333px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%;
    
                        &::after {
                            content: '';
                            display: inline-block;
                            background-image: url('../images/faqdown.svg');
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: 25px;
                            width: 25px;
                            height: 25px;
                            transition: all 0.3s ease;
                        }
                    }
    
                    &:not(.collapsed) {
                        color: #A0DDDE !important;
                        font-size: 16.333px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 140%;
                        border: none !important;
    
                        &::after {
                            content: '';
                            display: inline-block;
                            background-image: url('../images/faqdown.svg');
                            background-position: center;
                            background-repeat: no-repeat;
                            background-size: 25px;
                            width: 25px;
                            height: 25px;
                            transform: rotate(180deg);
                            transition: all 0.3s ease;
                        }
                    }
                }
            }
        }
    
        .accordion-body {
            padding: 15px;
        }
    }
    .left-gradient {
        left: -4%;
        position: absolute;
        top: -30%;
    z-index: -1;
      
    
    
        .gradient-img {
            height: 922px;
        }
    }
    
    .right-gradient {
        position: absolute;
        bottom: -19%;
        overflow: hidden;
        right: -9%;
        transform: rotate(180deg);
    z-index: -1;
        .gradient-img {
            height: 922px;
        }
    }
}