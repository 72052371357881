.road-map{
margin-bottom: 2rem;

.road-map-div{
    border-radius: 29.63px;
border-top: 1.111px solid #FFF;
border-right: 1.111px solid #FFF;
border-bottom: 1.111px solid #FFF;
background: linear-gradient(105deg, rgba(255, 255, 255, 0.11) -3.07%, rgba(255, 255, 255, 0.11) 49.04%, rgba(255, 255, 255, 0.00) 97.84%);
box-shadow: 0px 2.963px 4.444px 0px rgba(0, 0, 0, 0.25);
width: 100%;
height: 442px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
gap: 20px;
.h-text-1{
    background: var(--1121, linear-gradient(90deg, #FF0048 0%, #FFF 24.5%, #FF0048 48%, #FFF 73.5%, #FF0048 100%));
background-clip: text;
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
text-align: center;
font-size: 45.556px;
font-style: normal;
font-weight: 600;
line-height: normal;
text-transform: capitalize;
}
}

}