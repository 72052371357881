.banner {
  .banner-content-div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;
  }

  .whitepaper-btn {
    border-radius: 73px;
    background: linear-gradient(90deg, #D0F 0%, #FF0048 100%);
  }

  .btn-temp {
    border-radius: 61px;
    border: 3px solid #D0F;
    background: transparent;

  }

  .video-banner {

    width: 100%;
    height: 100vh;
    /* Set to full viewport height */
    overflow: hidden;
    // background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 75.26%);
  }
// .video-div{
//   position: relative;
// }
.linear-div{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 75.26%);
position: absolute;
width: 100%;
height: 100%;
bottom: 0;
left: 0;
}
  .video-background {
    position: absolute;
    top: -20%;
    left: 0;
    width: 100%;
    height: 100vh;
    object-fit: cover;
    /* Ensures the video covers the entire area */
    z-index: -1;
    /* Puts the video behind other content */
  }

  .content {
    position: absolute;
    top: 69%;
    /* Centers content vertically */
    left: 50%;
    /* Centers content horizontally */
    transform: translate(-50%, -50%);
    /* Adjusts positioning to truly center */
    color: white;
    /* Text color */
    text-align: center;
    z-index: 1;
    /* Ensures content is on top of the video */
  }

  .left-gradient {
    position: absolute;
    bottom: -8%;
    left: -14%;
    z-index: -1;

    .gradient-img {
      height: 922px;
      width: 84%;
    }
  }

  .right-gradient {
    position: absolute;
    bottom: -30%;
    overflow: hidden;
    right: -10%;
    transform: rotate(180deg);
z-index: -1;
    .gradient-img {
      height: 922px;
      width: 84%;
    }
  }

  .form-banner {

    width: 100%;
    height: 100%;
    // overflow: hidden;
background-image: url(../images/bg-image.svg);
background-position: center;
background-repeat: no-repeat;
background-size: cover;
    .banner-content-div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
    }
  }
@media all and (max-width: 1600px) {
   .video-background {
    position: absolute;
    top: -21%;
  }
    }
  @media all and (max-width:767px) {
    .content {
      width: 100%;
    }
    .video-banner{
      height: 100vh;
    }
    .whitepaper-btn {
      width: 100%;
      margin-bottom: 20px;
      margin-top: 10px;
    }
  .form-banner {
  height: 100%;
.banner-content-div {
  height: 65vh;
}
  }
  
  .btn-temp {
    width: 100%;
    margin-bottom: 20px;

  }
  .video-background{
    top: -37%;
  }
  .linear-div{
    bottom: -1px;
  
      background: transparent; 
       position: relative;
       z-index: -1;
  }
  .content {
   
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000 75.26%);
    top: 76%;
  }
  .right-gradient,.left-gradient{
    z-index: -5;
  }
  .btn-temp {
    padding: 10px !important;
  }
  }

}