/* Custom styles for Slick slider pagination dots */
.slick-dots li button:before {
  font-size: 17px !important;
  width: 17px !important;
  height: 17px !important;
  color: #D9D9D9 !important;
  opacity: 1 !important;
  /* Set the color you want for the dots */

}

.slick-dots li.slick-active button:before {
  color: #2E94CB !important;
  /* Set the color you want for the active dot */
}

.mobile-view {
  display: none;
}

.text-percentage {
  background: linear-gradient(89deg, #FF0048 0.93%, #D0F 67.41%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.stack-duration-btn {
  border-radius: 59px;
  background: rgba(255, 255, 255, 0.05);
}

.blur-effect {
  border-radius: 18px;
  background: rgba(255, 255, 255, 0.05);
}

.stacck-gradient-btn {
  border-radius: 49px;
  background: linear-gradient(89deg, #FF0048 0.93%, #D0F 67.41%);
}

.stack-unactive {
  border-radius: 49px;
  background: rgba(255, 255, 255, 0.05);
}

.submit-btn {
  border-radius: 49px;
  background: linear-gradient(89deg, #FF0048 0.93%, #D0F 67.41%);
}

@media all and (max-width:767px) {
  .mobile-view {
    display: block;
  }

  .desktop-view {
    display: none;
  }
}

.loader {
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 5px solid #FFF;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: flex;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}