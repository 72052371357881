.main-form-div {
  // background-image: url(../../../assets//images/bannergradient.svg);
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  position: relative;
  margin-bottom: 100px;
  padding-top: 40px;
  p {
    margin-bottom: 0 !important;
  }
  .form-div {
    display: flex;
    justify-content: end;
    align-items: end;
    width: 74%;

    .timing-div {
      position: relative;
      top: 0;
      right: 0;
      margin: 90.938px 0px 17.507px 0px;
      border-radius: 15.86px;
      border: 0.793px solid #464646;
      background: linear-gradient(
        155deg,
        rgba(255, 255, 255, 0) -2.13%,
        rgba(255, 255, 255, 0.15) 136.58%
      );
      box-shadow: 0px 3.172px 38.856px 0px rgba(0, 7, 72, 0.12);
      backdrop-filter: blur(9.91235637664795px);
    }

    .price-div {
      position: absolute;
      top: 6%;
      right: 49.5%;
      border: 0.793px solid #464646;
    }
  }

  .main-stage-form-div {
    display: flex;
    justify-content: end;
    /* margin-left: 46px; */
    width: 71%;

    .stage-form-div {
      display: flex;
      width: 286.842px;
      height: 78.239px;
      padding: 15.86px;
      // flex-direction: column;
      justify-content: flex-start !important;
      align-items: center;
      gap: 15.86px;
      flex-shrink: 0;
      border-radius: 15.86px;
      border: 0.793px solid #464646;
      background: linear-gradient(
        155deg,
        rgba(255, 255, 255, 0) -2.13%,
        rgba(255, 255, 255, 0.15) 136.58%
      );
      box-shadow: 25.376px 22.997px 38.856px 0px rgba(0, 7, 72, 0.08);
      backdrop-filter: blur(18.238737106323242px);
      position: relative !important;
      display: flex;
      justify-content: center;
      transform: skewX(-13deg);

      h4 {
        color: #fff;
        font-size: 19.032px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .stage-raised {
        color: #fff !important;
        font-size: 11.102px !important;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        width: 78.506px;
        text-align: left;
      }

      p {
        color: #24ff00;
        text-align: right;
        font-size: 11.102px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
  }

  .form-section {
    display: inline-flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    gap: 45px;
    border-radius: 37px;
    border: 1px solid var(--2, #464646);
    background: var(
      --1,
      linear-gradient(
        155deg,
        rgba(0, 0, 0, 0) -2.13%,
        rgba(255, 255, 255, 0.15) 136.58%
      )
    );
    width: 89%;
    margin-top: 20px;
  }

  .bal-section {
    display: flex;
    padding: 10px 16px 10px 25px;
    align-items: end;
    gap: var(--item-spacing-9, 9px);
    border-radius: 18px;
    background: rgba(255, 255, 255, 0.05);
    width: 100%;
    height: 107px;

    .bal-input-section {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    p {
      color: #fff;

      /* www.opz.com/Semantic/Label */
      font-family: "Inter";
      font-size: 14.875px;
      font-style: normal;
      font-weight: var(--font-weight-400, 500);
      line-height: var(--font-size-12, 12px);
      /* 110.345% */
    }
  }

  .form-control {
    display: flex;
    height: 20px;
    width: 100%;
    padding: 15px 8px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 17px;
    background: #000;
    color: white;
    border: transparent;

    &::placeholder {
      color: rgb(167, 165, 165);
    }

    &:focus {
      background: #000;
      color: white;
      border: transparent;
    }
  }

  .form-control:focus {
    box-shadow: none;
    border: transparent;
  }

  .form-control ::placeholder {
    color: #757575;
  }

  .dropdown-toggle {
    // height: 50px;
    min-height: var(--height-32, 32px);
    align-self: stretch;
    border-radius: 59px;
    background: #000;
    border-color: transparent;
    padding: 0 23px;
  }

  .next-value {
    color: #24ff00;
    font-size: 11.102px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .aps-value {
    color: #fff;
    font-size: 11.102px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
.refer-section {
  height: 60px !important;
}
.refer-value {
  color: #24ff00 !important;
  font-size: 11.102px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: normal;
}
.form-progress-div {
  width: 100%;
  padding: 0px 40px;
  margin-top: 20px;
  .progress {
    width: 100%;
    height: 5px;
    .progress-bar {
      background: linear-gradient(
        99deg,
        #ff0048 -85.03%,
        #d0f -41.79%,
        #ff0048 -0.31%,
        #d0f 44.7%,
        #ff0048 91.48%
      );
    }
  }
}
.value-showing-div {
  justify-content: space-between;
  align-items: center !important;

  .next-value {
    color: #24ff00 !important;
    font-size: 14.102px !important;
    font-style: normal !important;
    font-weight: 700 !important;
    line-height: normal;
  }

  .aps-value {
    color: #fff !important;
    font-size: 14.102px !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
  }
}

.connect-wallet {
  display: flex;
  height: 58px;
  padding: 21.99px 112.698px 20.01px 112.72px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 49px;

  background: linear-gradient(
    89deg,
    #ff0048 1.02%,
    #d0f 43.73%,
    #ff0048 84.7%,
    #d0f 129.16%,
    #ff0048 175.37%
  );
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-color: transparent;
  width: 100%;
  white-space: nowrap;
}

.price-div {
  display: flex;
  width: 100.093px;
  height: 66.121px;
  padding: 15.86px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15.86px;
  flex-shrink: 0;
  border-radius: 15.86px;
  border: 0.793px solid #56527c;
  background: linear-gradient(
    155deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  box-shadow: 0px 3.172px 38.856px 0px rgba(0, 7, 72, 0.12);
  backdrop-filter: blur(9.91235637664795px);
  position: absolute;
  top: 72%;
  right: 66%;
  transform: skewX(-13deg);

  h4 {
    color: #fff;
    font-size: 15.86px;
    font-style: normal;
    font-weight: 600;
    line-height: 14.815px;
    /* 93.411% */
    width: 71.678px;
  }

  span {
    color: #fff;
    // font-family: "Clash Display";
    font-size: 11.102px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width: 78.506px;
  }
}

.timing-div {
  display: flex;
  width: 340.308px;
  height: 78.782px;
  padding: 15.86px;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15.86px;
  flex-shrink: 0;
  border-radius: 15.86px;
  border: 0.793px solid #56527c;
  background: linear-gradient(
    155deg,
    rgba(255, 255, 255, 0) -2.13%,
    rgba(255, 255, 255, 0.15) 136.58%
  );
  box-shadow: 0px 3.172px 38.856px 0px rgba(0, 7, 72, 0.12);
  backdrop-filter: blur(9.91235637664795px);
  color: #fff;
  position: absolute;
  top: 74%;
  right: 23.5%;
  transform: skewX(-13deg);

  .day-div {
    display: flex;
    width: 41.842px;
    height: 100%;
    // padding: 15.86px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    // gap: 15.86px;
    flex-shrink: 0;
    border-radius: 15.86px;

    h4 {
      color: #fff;
      // font-family: "Clash Display";
      font-size: 25.376px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      width: 81.678px;
    }

    p {
      color: #fff;
      // font-family: "Clash Display";
      font-size: 16.102px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      width: 78.506px;
    }
  }
}

@media all and (max-width: 1690px) {
  .live-div {
    top: 23%;
    right: 9%;
  }
}

@media all and (min-width: 991px) {
  .main-form-div .form-div .price-div {
    top: -66%;
    right: 92.5%;
  }
  .main-form-div{
    margin-top: 54px;
  }
  .banner .form-banner .banner-content-div {
    height: 65vh;
}
}
@media all and (max-width: 991px) {
  .main-form-div .form-div .price-div {
    top: -66%;
    right: 92.5%;
  }
  .banner .form-banner .banner-content-div{
    margin-top: 106px;
  }
}
@media all and (min-width:600px) and (max-width:768px){
  .main-form-div .form-div .timing-div {
    right: 35% !important;
  }
}
@media all and (max-width: 767px) {
  .main-form-div {
    background-image: url(../images/big-gradient.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    .form-div {
      .timing-div {
        position: relative;
        top: 0;
        right: 5%;
        margin: 29.938px 0px 17.507px 0px;
        width: 273.308px;
        height: 66.782px;
        justify-content: flex-start;

        .day-div {
          display: flex;
          width: 25.842px;

          h4 {
            font-size: 22px;
          }
        }
      }

      .price-div {
        top: -82%;
        right: 95%;
        height: 60.121px;

        h4 {
          font-size: 13px;
        }
      }
    }

    .main-stage-form-div {
      .stage-form-div {
        width: 226.842px;
        height: 64.239px;
        padding: 10.86px;

        h4 {
          color: #fff;
          font-size: 14.032px;
        }
      }
    }

    .form-section {
      width: 100%;
 
    }

    .drop-btn-form {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 100%;
    }

    .dropdown-toggle {
      height: 39px;
      // margin-top: 10px;
    }

    .bal-section {
      height: 100%;
      display: block;

      .bal-input-section {
        gap: 10px;
        margin-top: 15px;
      }
    }

    .ustd-form-control {
      width: 90%;
    }

    .ustdlogo-icon {
      width: 58%;
    }
    .form-progress-div {
      width: 70%;
      padding: 0px;
      margin: 20px auto;
    }
  }
}
