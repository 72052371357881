@import '../SCSS/Header.scss';
@import '../SCSS/Banner.scss';
@import '../SCSS/Tokenomics.scss';
@import '../SCSS/RoadMap.scss';
@import '../SCSS/NFT.scss';
@import '../SCSS/Buy.scss';
@import '../SCSS/FAQ.scss';
@import '../SCSS/footer.scss';
@import '../SCSS/VissionMission.scss';
@import '../SCSS/About.scss';
@import '../SCSS/WalletOption.scss';
@import '../SCSS/FormBanner.scss';

ul{
    padding-left: 0;
}

.h-text-1 {
    font-size: 70px;
    font-weight: 900;
    line-height: 55px;
    letter-spacing: -0.01em;
    text-align: center;
    background: linear-gradient(88.91deg,
            #ff0048 0.93%,
            #dd00ff 17.22%,
            #ff0048 32.84%,
            #dd00ff 49.79%,
            #ff0048 67.41%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;

}

.h-text-2 {
    font-size: 50px;
    font-weight: 900;
    line-height: 55px;
    letter-spacing: -0.01em;
    text-decoration-skip-ink: none;
    color: #ffffff;
}

.p-text-1 {
    color: #FFF;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    font-family: urbanist;
}

.left-gradient {
    position: absolute;
    bottom: 0;
    left: -15%;
z-index: -1;

    .gradient-img {
        width: 610px;
        height: 610px;
    }
}
.right-gradient {
    position: absolute;
    bottom: -62%;
    overflow: hidden;
    right: -9%;
    transform: rotate(180deg);

    .gradient-img {
        height: 922px;
    }
}
.mobile-view{
    display: none;
}
// @media all and (max-width:1100px){
//     .mobile-view{
//         display: block !important;
//     }
//     .desktop-view{
//         display: none !important;
//     }
// }

@media all and (max-width:767px){
    .h-text-1 {
        font-size: 46px;
        line-height: 42px;
    }
    .h-text-2 {
        font-size: 31px;
        font-weight: 900;
        line-height: 39px;
        text-align: center;
    }
    .p-text-1 {
   
        font-size: 14px;
    }
    .mobile-view{
        display: block !important;
    }
    .desktop-view{
        display: none !important;
    }
    .left-gradient {
        z-index: -5;
    }
    .right-gradient {
        z-index: -5;
    }
}