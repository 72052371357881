@font-face {
    font-family: 'CabinetGrotesk';
    src: url('../fonts/CabinetGrotesk-Thin.otf') format('opentype');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CabinetGrotesk';
    src: url('../fonts/CabinetGrotesk-Extralight.otf') format('opentype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CabinetGrotesk';
    src: url('../fonts/CabinetGrotesk-Light.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CabinetGrotesk';
    src: url('../fonts/CabinetGrotesk-Regular.otf') format('opentype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CabinetGrotesk';
    src: url('../fonts/CabinetGrotesk-Medium.otf') format('opentype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'CabinetGrotesk';
    src: url('../fonts/CabinetGrotesk-Bold.otf') format('opentype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'CabinetGrotesk';
    src: url('../fonts/CabinetGrotesk-Extrabold.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}


/* @font-face {
    font-family: 'CabinetGrotesk';
    src: url('../fonts/CabinetGrotesk-Black.otf') format('opentype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
} */
