.abt-sec{
    background-image: url(../images/bg-image.svg);
background-repeat: no-repeat;
background-size: cover;
.about{
margin-bottom: 100px;

.banner-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    }


.about-video-div{
    width: 100%;
    height: 355px;
    border-radius: 18px;
    overflow: hidden;
    .abt-video{
        width: 100%;
        height: 100%;
        object-fit: fill;
    }
}
.btn-div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 20px;
}
.whitepaper-btn{
    display: inline-flex;
padding: 10px 20px;
align-items: flex-start;
gap: 7.407px;
border-radius: 73px;
background: linear-gradient(90deg, #D0F 0%, #FF0048 100%);
color: #FFF;
font-family: Urbanist;
font-size: 18px;
font-style: normal;
font-weight: 600;
line-height: normal;
}

}
@media all and (max-width:767px){
    .left-gradient{
        display: none;
    }
    .whitepaper-btn{
    padding: 8px 20px;
    }
}
}