.header-top-div {
  width: 100%;
  height: 24px;
  background: linear-gradient(
    95.71deg,
    #ff0048 4.54%,
    #dd00ff 70.47%,
    #ff0048 133.71%,
    #dd00ff 202.33%,
    #ff0048 273.64%
  );
}

.header-links {
  color: white;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 15px 20px;
  font-family: Urbanist;
  border-radius: 53px;
  transition: all 0.3s ease;

  &:hover {
    display: flex;

    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 53px;
    background: linear-gradient(
      96deg,
      #ff0048 4.54%,
      #d0f 70.47%,
      #ff0048 133.71%,
      #d0f 202.33%,
      #ff0048 273.64%
    );
    color: white;
  }
  &:active {
    display: flex;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 53px;
    background: linear-gradient(
      96deg,
      #ff0048 4.54%,
      #d0f 70.47%,
      #ff0048 133.71%,
      #d0f 202.33%,
      #ff0048 273.64%
    );
    color: white;
  }
  &:focus {
    display: flex;
    padding: 15px 20px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 53px;
    background: linear-gradient(
      96deg,
      #ff0048 4.54%,
      #d0f 70.47%,
      #ff0048 133.71%,
      #d0f 202.33%,
      #ff0048 273.64%
    );
    color: white;
  }
}

.connect-wallet-btn {
  border-radius: 30px;
  background: linear-gradient(
    99deg,
    #ff0048 -85.03%,
    #d0f -41.79%,
    #ff0048 -0.31%,
    #d0f 44.7%,
    #ff0048 91.48%
  );
  display: flex;
  padding: 10px;
  align-items: flex-start;
  gap: 7.407px;
  border-color: transparent;
}
.mobile-connect-wallet-btn {
  border-radius: 0px 53px 53px 0px;
  background: linear-gradient(
    99deg,
    #ff0048 -85.03%,
    #d0f -41.79%,
    #ff0048 -0.31%,
    #d0f 44.7%,
    #ff0048 91.48%
  );
  display: flex;
  padding: 3px 10px;
  align-items: flex-start;
  gap: 7.407px;
  border-color: transparent;
  border-radius: 30px;
}
.header-gap{
  gap: 1rem;
  padding-left: 1;
}
@media all and (max-width: 1200px) {
  .header-links{
    font-size: 14px;
  }
  .header-gap{
    gap: 0rem;
  }
}