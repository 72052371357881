.main-how-buy {
    margin-bottom: 2rem;


    .buy-head {
        margin-bottom: 45px;

    }



    .how-buy {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;

        .card {
            border-radius: 20px;
            // border-radius: 14.815px;
            border: 1.111px solid #695a5e;
            background: linear-gradient(155deg, rgba(255, 255, 255, 0.00) -2.13%, rgba(255, 255, 255, 0.15) 136.58%);
            box-shadow: 0px 2.963px 36.296px 0px rgba(0, 7, 72, 0.12);
            backdrop-filter: blur(9.259259223937988px);
            display: flex;
            width: 100%;
            height: 100%;
            padding-bottom: 14.815px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex-shrink: 0;
        }

        .card-img-top {
            width: 100%;
            height: 100%;
        }

        .card-title {
            color: #2F80ED;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .card-text {
            color: #FFF;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }

    .buy-aps-div {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;

        .btn-buy-aps {
            display: inline-flex;
            padding: 11.111px 18.519px;
            align-items: flex-start;
            gap: 7.407px;
            border-radius: 44.444px;
            background: var(--Blue-1, #2F80ED);
            color: #FFF;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }
    }
}
@media all and (max-width:767px){
    .main-how-buy .how-buy {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
    }

}