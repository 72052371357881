.tokenomics {
    margin-bottom: 150px;

    // background-image: url(../images/bg-image.svg);
    // background-repeat: no-repeat;
    // background-size: cover;
    .left-gradient {
        left: 0%;
z-index: -1;
        .gradient-img {
            height: 922px;
        }
    }

    .right-gradient {
        position: absolute;
        bottom: -62%;
        overflow: hidden;
        right: 0%;
        transform: rotate(180deg);
        z-index: -1;

        .gradient-img {
            height: 922px;
        }
    }

    @media all and (max-width:767px) {
        // .right-gradient {
        //     display: none;
        // }

        // .left-gradient {
        //     display: none;
        // }
    }
}