.vsms{
    position: relative;
    .vsms-img-div{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
        margin-top: 50px;
    }
    .mission-div{
        margin-top: 50px;
    }
}